import { ICampaign, ICampaignAudit, ICampaignTag } from "../dtos/campaigns";
import { handlStatusCodeMessage, jsonToFormData, logSecretly } from "../helpers";
import { axiosInstance } from "../store";

export function fetchCampaigns(page = 0, size = 10, type = "", tag = "", status = ""): Promise<{
    campaigns: ICampaign[],
    totalCount: number,
}> {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/campaign?status=${status}&type=${type}&tag=${tag}&page=${page}&size=${size}&sort=createdAt,desc`)
            .then((res) => {
                logSecretly("GET-campaign-SUCCESS", res);
                resolve({
                    campaigns: res.data,
                    totalCount: parseInt(res.headers['x-total-count'])
                })
            }).catch((err) => {
                handlStatusCodeMessage({ code: err.response?.status, entity: "campaign" })
                logSecretly("GET-campaign-FAILURE", err?.response)
                reject(err?.response);
            })
    })
}

export function fetchCampaign(campaignId: number): Promise<ICampaign> {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/campaign/${campaignId}?`)
            .then((res) => {
                logSecretly("GET-CAMPAIGN", res)
                resolve(res.data);
            }).catch((err) => {
                handlStatusCodeMessage({ code: err.response?.status, entity: "campaign" })
                logSecretly("GET-CAMPAIGN-FAILURE", err?.response)
                reject(err?.response);
            })

    })
}
export function fetchCampaignTags(): Promise<ICampaignTag[]> {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/campaign/tags`)
            .then((res) => {
                logSecretly("GET-CAMPAIGN", res)
                resolve(res.data);
            }).catch((err) => {
                handlStatusCodeMessage({ code: err.response?.status, entity: "campaign" })
                logSecretly("GET-CAMPAIGN-FAILURE", err?.response)
                reject(err?.response);
            })
    })
}
export function createCampaign(campaign: any): Promise<ICampaign> {
    return new Promise((resolve, reject) => {
        const formData = jsonToFormData(campaign)
        axiosInstance.post(`${process.env.REACT_APP_API_URL}catalog/campaign/`, formData, {
            headers: {
                "Accept-Language": "",
                "Content-Type": "multipart/form-data",
                "Accept": "*/*",
            }
        })
            .then(res => {
                logSecretly("CREATE-CAMPAIGN", res);
                resolve(res.data);
            }).catch(err => {
                handlStatusCodeMessage({ code: err.response?.status, entity: "campaign" })
                logSecretly("CREATE-CAMPAIGN-ERROR", err?.response);
                reject(err.response);
            })
    })
}
export function updateCampaign(campaignId: number, campaign: ICampaign): Promise<ICampaign> {
    return new Promise((resolve, reject) => {
        const formData = jsonToFormData(campaign)
        axiosInstance.put(`${process.env.REACT_APP_API_URL}catalog/campaign/${campaignId}`, formData, {
            headers: {
                "Accept-Language": "",
                "Content-Type": "multipart/form-data",
                "Accept": "*/*",
            }
        })
            .then(res => {
                logSecretly("UPDATE-CAMPAIGN", res);
                resolve(res.data);
            }).catch(err => {
                handlStatusCodeMessage({ code: err.response?.status, entity: "campaign" })
                logSecretly("UPDATE-CAMPAIGN-ERROR", err?.response);
                reject(err.response);
            })
    })
}
export function fetchCampaignAudit(campaignId: number, page = 0, size = 10, sort?: string, successStatus?: boolean | undefined): Promise<{ audit: ICampaignAudit[], totalCount: number }> {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/campaign/${campaignId}/history?${successStatus ? `succeed=${successStatus}` : ""}&sort=${sort}&page=${page}&size=${size}`)
            .then((res) => {
                try {
                    resolve({ audit: res.data, totalCount: parseInt(res.headers["x-total-count"]) });
                } catch (e) { }
            })
            .catch((err) => {
                reject({ audit: [], totalCount: 0 })
            })
    })
}
