import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import reducers from "../reducers";
import axios from "axios";
import { isAuth } from "./middlewares";
import thunk from "redux-thunk";
import rootReducer, { templateReducers } from "../redux/root-reducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URI,
  responseType: "json",
});

axiosInstance.interceptors.request.use((config: any) => {
  try {
    const globalReducer = store.getState().globalReducer;
    const languageReducer: any = store.getState().LanguageSwitcher;
    const storefrontId = globalReducer.authUser.storefront_id;
    const tenant_id = globalReducer.authUser?.tenant?.id ?? "";
    config.headers["Accept-Language"] = languageReducer.language.locale;
    config.headers.Authorization = `Bearer ${!globalReducer.authUser.token ? "" : globalReducer.authUser.token
      }`;

    const params = {
      ...config.params,
      tenant_id: tenant_id,
    };
    if (!config.url.includes('/v3/product/products')) {
      params.storefront_id = storefrontId
    }
    config.params = params
    return config;
  } catch (e) {
    return config;
  }
});

export const history = createBrowserHistory();

// preventing pushing same route to history
const prevHistoryPush = history.push;
let lastLocation: any = history.location;
history.listen((location) => {
  lastLocation = location;
});
history.push = (pathname: any, state = {}) => {
  if (
    lastLocation === null ||
    pathname !==
    lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(pathname, state);
  }
};
// end preventing pushing same route to history

const historyMiddleware = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();

let composer;
let middleware = [sagaMiddleware, isAuth, historyMiddleware, thunk];
if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  middleware.push(logger);
} else {
  composer = compose; // add support for Redux dev tools
}

const storeReducers = combineReducers({
  ...reducers,
  ...templateReducers,
  router: connectRouter(history),
});

export const store = createStore(
  storeReducers,
  composer(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof storeReducers>;
