import { axiosInstance } from "../store";
import { handlStatusCodeMessage, jsonToFormData, jsonToFormData_v2 } from "../helpers";

export function fetchCategoriesMenu(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/category?level=1&page=0&size=1000`)
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
            else {
                return []
            }
        })
        .catch((err) => {
            return []
        })
}

//
export function fetchParentCategoriesList(family_id: string | string[]): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/category?parent_categories=0&family_id=${family_id}&size=1000`)
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
            else {
                return []
            }
        })
        .catch((err) => {
            return []
        })
}
export function fetchCategoriesList(parentId: string): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/category?parent_categories=${parentId}&size=1000`)
        .then((res) => {
            if (res.status == 200) {
                return res.data;
            }
            else {
                return []
            }
        })
        .catch((err) => {
            return []
        })
}
//
export function fetchRoots(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/category?parent_categories=0&page=0&size=50`)
        .then(async (res) => {
            if (res.status == 200) {
                const all = await Promise.all(res.data.map(async (parent: any) => {
                    const levelTwo = await fetchCategoriesList(parent.id);
                    return {
                        ...parent,
                        levelTwo: levelTwo
                    }
                }))
                return all;

            }
            else {
                return []
            }
        })
        .catch((err) => {
            return []
        })
}
export function saveCategory(cat: any, familyId: string | string[]): Promise<any> {
    let formData = jsonToFormData_v2(cat);
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}catalog/category?family_id=${familyId}`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'catalog' });
                return false;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return false;
        })
}
export function getCategory(categoryId: string): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/category/${categoryId}`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'catalog' });
                return null;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return null;
        })
}
export function updateCategory(categoryId, cat: any, family_id: string[] | string): Promise<any> {
    let formData = jsonToFormData_v2(cat);
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}catalog/category/${categoryId}?family_id=${family_id}`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'catalog' });
                return false;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return false;
        })
}