import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  UPDATE_APP_STATUS_SAGA,
  IUpdateAppStatusActionSaga,
  UPDATE_AUTH_TOKEN_SAGA,
  IUpdateAuthTokenActionSAGA,
  UPDATE_AUTH_USER_SAGA,
  IUpdateAuthUserActionSAGA,
  UPDATE_GLOBAL_MODAL_SAGA,
  IUpdateGlobalModalActionSAGA,
  LOGIN_AUTH_USER_SAGA,
  ILoginAuthUserActionSAGA,
  IUpdateWebsocketClientActionSAGA,
  UPDATE_WEBSOCKET_CLIENT_SAGA,
} from "./types";
import {
  updateStatusProps,
  updateAuthTokenExpProps,
  updateAuthUserProps,
  updateGlobalModalProps,
  updateWebsocketClientProps,
} from "../../reducers/global/types";
import { validateUser } from "../../api/users";
import { keycloak, initOptions } from "../../store/keycloak";
import usersList from "../../users.json";
import { login } from "../../api/auth";
import { push } from "connected-react-router";
import IBranch from "../../dtos/IBranch";
import { store } from "../../store";
import Sales from "../../containers/Users/Sales";
import { fetchVendor, verifyVendor } from "../../api/vendors";

function* updateAppStatus(action: IUpdateAppStatusActionSaga) {
  yield put(updateStatusProps({ appStatus: action.payload.appStatus }));
}

export function* watchUpdateAppStatus() {
  yield takeEvery(UPDATE_APP_STATUS_SAGA, updateAppStatus);
}

function* updateAuthTokenExp(action: IUpdateAuthTokenActionSAGA) {
  yield put(
    updateAuthTokenExpProps({ authTokenExp: action.payload.authTokenExp })
  );
}

export function* watchUpdateAuthTokenExp() {
  yield takeLatest(UPDATE_AUTH_TOKEN_SAGA, updateAuthTokenExp);
}

function* updateAuthUser(action: IUpdateAuthUserActionSAGA) {
  let authUser;
  // authenticate with keycloak
  if (action.payload.authWithKeycloak) {
    const auth: boolean = yield keycloak.init({ onLoad: initOptions.onLoad });
    //Token Refresh
    setInterval(() => {
      keycloak.updateToken(60).then((refreshed: any) => {
        if (refreshed) {
          // console.info('Token refreshed' + refreshed);
          const currentAuthUser = store.getState().globalReducer.authUser;
          store.dispatch(updateAuthUserProps({ authUser: { ...currentAuthUser, ...keycloak } }));
        } else {
          /*console.warn('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          */
        }
      }).catch(() => {
        // console.error('Failed to refresh token');
      });
    }, 6000)
    if (!auth) {
      authUser = {
        error: "failed to login from keycloak",
      };
    } else {
      authUser = keycloak;
    }
  } else {
    // authenticate with basic
    authUser = {
      token: localStorage.getItem("authToken"),
    };
  }
  yield put(updateAuthUserProps({ authUser: authUser }));
  const validationData: {
    userValidated: boolean;
    service_account_id: number | null;
    my_branches: IBranch[] | null;
    user_permissions: { id: string, name: string }[]
    user_id: number | null,
    user_profile: any,
    storefronts: any,
    user_info: any,
    user_email: any,
    user_first_name: any,
    user_last_name: any,
  } = yield call(validateUser, authUser);
  yield localStorage.setItem("user_id", validationData.user_id ? validationData.user_id.toString() : "")
  authUser = {
    ...authUser,
    user_permissions: validationData.user_permissions,
    my_branches: validationData.my_branches,
    userValidated: validationData.userValidated,
    user_id: validationData.user_id,
    tenant: validationData.user_profile.tenant,
    storefronts: validationData.storefronts,
    email: validationData.user_email,
    first_name: validationData.user_first_name,
    last_name: validationData.user_last_name,
  };
  if (process.env.REACT_APP_IS_VENDOR == 'true') {
    const vendorData = yield call(fetchVendor, authUser.tenant?.id)

    if (vendorData) {
      yield localStorage.setItem("storefront", vendorData.storefronts[0]?.storefront_id);
      authUser = {
        ...authUser,
        vendor_id: vendorData.id,
        storefront_id: vendorData.storefronts[0]?.storefront_id
      }
    }
    else {
      return;
    }
  } else {
    if (validationData.storefronts && validationData.storefronts.length > 0) {
      let selectedStoreFront = validationData.storefronts.find(storefront => storefront.storefront_id == localStorage.getItem("storefront"));
      if (selectedStoreFront) {
        yield localStorage.setItem("storefront", selectedStoreFront.storefront_id);
        authUser = {
          ...authUser,
          storefront_id: selectedStoreFront?.storefront_id
        }
      } else {
        yield localStorage.setItem("storefront", validationData.storefronts[0].storefront_id);
        authUser = {
          ...authUser,
          storefront_id: validationData.storefronts[0]?.storefront_id
        }
      }
    } else {
      yield localStorage.setItem("storefront", "1");
      authUser = {
        ...authUser,
        storefront_id: "1"
      }
    }
  }
  yield put(updateAuthUserProps({ authUser: authUser }));
  let salesPer = authUser.user_permissions.find((permission: { id: string, name: string }) => {
    return permission.name === "USER_SALES_STAFF_ASSIGN"
  })
  if (salesPer) Sales.getInstance();
}

export function* watchUpdateAuthUser() {
  yield takeLatest(UPDATE_AUTH_USER_SAGA, updateAuthUser);
}

function* updateGlobalModal(action: IUpdateGlobalModalActionSAGA) {
  yield put(updateGlobalModalProps({ ...action.payload }));
}

export function* watchUpdateGlobalModal() {
  yield takeLatest(UPDATE_GLOBAL_MODAL_SAGA, updateGlobalModal);
}

function* loginAuthUser(action: ILoginAuthUserActionSAGA) {
  let authUser;
  const results: { token: string; message?: string } = yield call(
    login,
    action.payload.email,
    action.payload.password
  );
  const existingUser: any = usersList.find(
    (u) => u.email == action.payload.email
  );
  if (existingUser) {
    if (results.token) {
      localStorage.setItem("authToken", results.token);
      authUser = {
        token: results.token,
      };
    } else if (results.message) {
      alert(results.message);
    }
  } else {
    alert("User not found");
  }
  yield put(updateAuthUserProps({ authUser: authUser }));
}

export function* watchLoginAuthUser() {
  yield takeLatest(LOGIN_AUTH_USER_SAGA, loginAuthUser);
}

function* updateWebsocketClient(action: IUpdateWebsocketClientActionSAGA) {
  yield put(updateWebsocketClientProps({ ...action.payload }));
}

export function* watchWebsocketClient() {
  yield takeLatest(UPDATE_WEBSOCKET_CLIENT_SAGA, updateWebsocketClient);
}

export default [
  watchUpdateAppStatus(),
  watchUpdateAuthTokenExp(),
  watchUpdateAuthUser(),
  watchUpdateGlobalModal(),
  watchLoginAuthUser(),
  watchWebsocketClient(),
]
