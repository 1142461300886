export const shipmentStatusMap = [
    "Incomplete",
    "Open",
    "Ready For Picking",
    "Packed",
    "Shipping",
    "Complete",
    "Cancel",
    "Returned",
    "Failed",
    "Cancellation In Progress",
    "Draft",
    "Need Approval",
]

export const statusColorCode = {
    GREEN: "#EEFBF4",
    YELLOW: "#FFF8EB",
    RED: "#FEF1F1",
    BLUE: "#E6E9F3"
}
export const statusTextColorCode = {
    GREEN: "#50CE89",
    YELLOW: "#FCB831",
    RED: "#EC7373",
    BLUE: "#002182"
}

export const statusHandler = (status: string): string => {
    switch (status) {
        case "Approved": case "Accepted":
            return statusColorCode.GREEN;
        case "PartiallyApproved": case "Active":
            return statusColorCode.YELLOW;
        case "NotApproved": case "Deleted":
            return statusColorCode.RED;
        default:
            return statusColorCode.BLUE;
    }
}

export const statusTextHandler = (status: string): string => {
    switch (status) {
        case "Approved": case "Accepted":
            return statusTextColorCode.GREEN;
        case "PartiallyApproved": case "Active":
            return statusTextColorCode.YELLOW;
        case "NotApproved": case "Deleted":
            return statusTextColorCode.RED;
        default:
            return statusTextColorCode.BLUE;

    }
}
export const actionsMap = {
    "order_status": "Changed Order status",
    "order_tracking_number": "Order tracking number",
    "order_courier": "Order courier",
    "order_agent": "Order agent",
    "CREATE": "Order Created",
    "order_total_discount": "Order total discount",
    "order_address": "Changed Order address",
    "order_staff_comments": "Order staff comments",
    "order_delivery_fees": "Order delivery fees",
    "order_total": " Order total",
    "order_subtotal": "Order subtotal",
    "order_payment_method": "Order payment method",
    "order_rate": "Oredr rate",
    "order_rate_comment": "Oredr rate comment",
    "action_type": "Action type",
    "action_changed_at": "Action Changed at",
    "action_changed_by": "Action Changed by",
    "action_comment": "Action Comment",
    "order_delivery_priority": "Order Delivery Priority",
    "order_user_id": "Order User Id",
    "order_promotion_discount": "Order promotion discount",
    "order_redeemed_discount": "Order Redeemed discount",
    "order_redeem_discount": "Order redeem discount",
    "order_redeem_discount_points": "Order redeem discount points",
    "order_comments": "Order comments",
    "order_order_line": "Order order line",
    "is_reactivation": "Reactivated",
}
export const homeLocations = {
    staff: "/dashboard/lead",
    patient: "/dashboard/patient",
    doctor: "/dashboard/doctor",
};