import _, { Primitive } from "lodash";
import { toast, ToastOptions } from "react-toastify";
import notification from "../components/Notification";
import { primitiveType } from "../dtos/global";
import AppLocale from "../config/translation";

declare global {
  interface Window {
    eventWaiters: any;
  }
}
const currentAppLocale = AppLocale["en"];

window.eventWaiters = {};

interface CustomMessage {
  text: string;
  type: "error" | "success" | "warning" | "info";
}

interface StatusCode {
  code: number;
  entity: string;
  text?: string;
}
/*//"Please upload a valid file type (PDF, DOC, DOCX)"
                          handlStatusCodeMessage({ code: 200, entity: "valid attachment type" });
                          return false;
                        }

                        const isLessThan5MB = file.size / 1024 / 1024 < 5;
                        if (!isLessThan5MB) {
                          //"Please upload a valid file size less than 5MB"
                          handlStatusCodeMessage({ code: 200, entity: "valid attachment size type" });*/
export const handlStatusCodeMessage = (
  statusCode: StatusCode,
  toastOptions?: ToastOptions
): void => {
  let message: CustomMessage = { text: "", type: "info" };
  switch (statusCode.code) {
    case 200 || 204:
      if (statusCode.entity === "cerate_order") {
        message = { text: `Order created successfully`, type: "success" };
      } else if (statusCode.entity == "valid attachment type") {
        message = {
          text: `Please upload a valid file type (PDF, DOC, DOCX)`,
          type: "error",
        };
      } else if (statusCode.entity == "valid attachment size type") {
        message = {
          text: `"Please upload a valid file size less than 5MB"`,
          type: "error",
        };
      } else if (statusCode.entity === "create_product") {
        message = { text: `Product created successfully`, type: "success" };
      } else if (statusCode.entity === "update-userGroups") {
        message = { text: `UserGroup updated successfully`, type: "success" };
      } else if (statusCode.entity === "delete usergroup") {
        message = { text: `UserGroup deleted successfully`, type: "success" };
      } else if (statusCode.entity === "create-userGroups") {
        message = { text: `UserGroup created successfully`, type: "success" };
      } else if (statusCode.entity === "create-module") {
        message = { text: `module created successfully`, type: "success" };
      } else if (statusCode.entity === "permission added to module") {
        message = {
          text: `Permission added to module scuccessfully`,
          type: "success",
        };
      } else if (statusCode.entity === "no permission assigned") {
        message = {
          text: `At least one permission must be selected for any group`,
          type: "error",
        };
      } else if (statusCode.entity === "minimum permission for Module") {
        message = {
          text: `Can't create Module without filling  the first permission`,
          type: "error",
        };
      } else if (statusCode.entity === "duplicate group") {
        message = { text: `UserGroup name already exist`, type: "error" };
      } else if (statusCode.entity === "empty group name") {
        message = { text: `Group name cannot be empty`, type: "error" };
      } else if (statusCode.entity === "name is required to add permission") {
        message = { text: `Name is required to add permission`, type: "error" };
      } else if (statusCode.entity === "permission exists in module") {
        message = {
          text: `Permission already exists in module`,
          type: "error",
        };
      } else if (statusCode.entity === "no module Name") {
        message = {
          text: `You have to add a module name first`,
          type: "error",
        };
      } else if (statusCode.entity == "module updated") {
        message = { text: `Module updated successfully`, type: "success" };
      } else {
        message = { text: `operation succeeded`, type: "success" };
      }
      break;
    case 202:
      message = { text: `operation succeeded`, type: "success" };
      break;
    case 201:
      message = {
        text: `${statusCode.entity} has been created successfully`,
        type: "success",
      };
      break;
    case 400:
      if (statusCode.entity === "vendor") {
        message = { text: `No profile found`, type: "error" };
        break;
      } else {
        message = { text: `${statusCode.text}`, type: "warning" };
        break;
      }
    case 401:
      message = { text: `you are unauthorized`, type: "error" };
      break;
    case 403:
      message = {
        text: `you are not allowed to perform this action`,
        type: "warning",
      };
      break;
    case 404:
      if (statusCode.entity === "po.vendor") {
        message = {
          text: `${currentAppLocale.messages["po.emptyMessage"]}`,
          type: "info",
        };
        break;
      }
      message = { text: `No profile found`, type: "error" };
      break;
    case 500:
      message = { text: `server exception`, type: "error" };
      break;
    case 502:
      message = { text: `bad gateway`, type: "error" };
      break;
    case 503:
      message = { text: `service temporary unavailable`, type: "error" };
      break;
    case 409:
      message = { text: `User Already Exists`, type: "error" };
      break;
    default:
      message = { text: "Something went wrong", type: "error" };
  }
  // overwrite default code message text
  message = {
    ...message,
    text: statusCode.text ? statusCode.text : message.text,
  };
  // call taost
  // (toast as any)[message.type](message.text, toastOptions);
  notification(message.type, message.text);
};
export const addQueryString = (
  locationSearch: string,
  name: string,
  value: any
): string => {
  const currentQueryStrings = locationSearch
    .replace("?", "")
    .split("&")
    .filter((val, i) => !val.includes(name) && val != "")
    .join("&");
  return currentQueryStrings == ""
    ? `${name}=${value}`
    : `${currentQueryStrings}&${name}=${value}`;
};
export function removeQueryParam(
  queryString: string,
  queryKey: string
): string {
  if (queryString.includes(queryKey)) {
    const splittedQuery = queryString.replace("?", "")?.split("&");
    return `${splittedQuery
      ?.filter((value) => !value.includes(queryKey))
      .join("&")}`;
  }
  return queryString;
}
export function getSureValue(value: any, type: primitiveType) {
  return typeof value == type ? value : undefined;
}
export function logSecretly(key: string = "", value: any) {
  if (process.env.REACT_APP_ENVIRONMENT == "development") {
    key ? console.log(key, value) : console.log(key, value);
  }
}
export function jsonToFormData(
  obj: any,
  form: FormData | undefined = undefined,
  nestedKey = ""
): FormData {
  const formData = new FormData();
  Object.keys(obj)?.map((property: string, index: number) => {
    if (typeof obj[property] == "object" && obj[property]?.type) {
      if (obj[property]) {
        formData.append(`${property}`, obj[property]);
      }
    } else if (typeof obj[property] == "object" && obj[property] != null) {
      jsonToFormData(obj[property], formData, property);
    } else if (form && obj[property] != null) {
      form.append(
        `${nestedKey ? `${nestedKey}.` : ""}${property}`,
        obj[property]
      );
    } else if (obj[property] != null) {
      formData.append(property, obj[property]);
    }
  });
  return formData;
}

export function jsonToFormData_v2(
  obj: any,
  form: FormData | undefined = undefined,
  nestedKey = ""
): FormData {
  const formData = form || new FormData();

  Object.keys(obj).forEach((property) => {
    if (obj[property] == null) return;

    if (typeof obj[property] === "object") {
      if (obj[property].type) {
        formData.append(property, obj[property]);
      } else if (Array.isArray(obj[property])) {
        obj[property].forEach((item, index) => {
          if (item?.type) {
            formData.append(`${property}[${index}]`, item);
          } else if (item !== undefined) {
            Object.keys(item).forEach((subKey) => {
              if (item[subKey] !== undefined) {
                formData.append(
                  `${property}[${index}].${subKey}`,
                  item[subKey]
                );
              }
            });
          }
        });
      } else {
        if (property.includes("Translations")) {
          Object.keys(obj[property]).forEach((lang) => {
            const langObject = obj[property][lang];
            Object.keys(langObject).forEach((descKey) => {
              formData.append(
                `${property}[${lang}].${descKey}`,
                langObject[descKey]
              );
            });
          });
        } else {
          jsonToFormData_v2(obj[property], formData, property);
        }
      }
    } else {
      formData.append(
        `${nestedKey ? `${nestedKey}.` : ""}${property}`,
        obj[property]
      );
    }
  });

  return formData;
}

export const currencyCode = () => {
  switch (localStorage.getItem("storefront")) {
    case "2":
      return "SAR";
    case "9":
      return "LYD";
    default:
      return "EGP";
  }
};
export const getArrayDifference = (arr1, arr2) => {
  return arr1.filter((item1) => !arr2.some((item2) => item1.id === item2.id));
};

/**
 * @function Extreme StyleObject Combiner
 * @argument styles is an array of style arrays, the style array must have the style object at 0 index
 * and the condition at index 1
 * @example esc([[stylesheet1, true], [stylesheet2, isLoggedIn]])
 */
export function esc(styles: any[]) {
  let styleObject = {};
  styles.map((sheet, index) => {
    if (sheet?.[1]) {
      styleObject = { ...styleObject, ...sheet[0] };
    }
  });
  return styleObject;
}

export function transform(data: any, dictionary: { [key: string]: string }) {
  const result: { [key: string]: any } = {};
  Object.keys(data).forEach((key) => {
    if (dictionary[key]) {
      result[dictionary[key]] = data[key];
    } else {
      result[key] = data[key];
    }
  });
  return result;
}
export function safelyParseInt(input: string | undefined): number {
  const parsed = parseInt(input);
  return isNaN(parsed) ? 0 : parsed;
}