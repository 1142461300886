import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";

const initialThemeState = {
    theme: 'light',
    setTheme: (() => null) as Dispatch<SetStateAction<string>>,
}

const ThemeContext = React.createContext(initialThemeState);
interface IThemeProviderProps {
    children: ReactNode,
}
export const ThemeProvider = ({ children }: IThemeProviderProps) => {
    const [theme, setTheme] = useState("light")
    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
    }, [theme])
    return (
        <ThemeContext.Provider value={{
            theme: theme,
            setTheme
        }}>
            <div className={`theme--${theme}`}>{children}</div>
        </ThemeContext.Provider>

    )
}