import axios from "axios";
import { IOrder } from "../dtos/IOrder";
import { handlStatusCodeMessage } from "../helpers";
import { axiosInstance } from "../store";

export function fetchSla(
  fdcFlag = false,
  status = "",
  ergency = "",
  page = 0,
  size = 25,
  sort = "",
  orderId = "",
): Promise<{ orders?: IOrder[]; totalOrdersCount?: number }> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}operation/order-ergency?status=${status}${fdcFlag ? `&fully_fdc=${fdcFlag}` : ''}&ergency=${ergency}&page=${page}&size=${size}&sort=${sort}&order_id=${orderId}`
    )
    .then((res) => {
      if (res.status == 200) {
        return {
          orders: res.data,
          totalOrdersCount: parseInt(res.headers["x-total-count"]),
        };
      } else {
        return { orders: [], totalOrdersCount: 0 };
      }
    })
    .catch((err) => {
      handlStatusCodeMessage({ code: err.response.status, entity: "order" });
      return { orders: [], totalOrdersCount: 0 };
    });
}

export function fetchSlaOrders(orderIdsList: number[]): Promise<IOrder[]> {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}order/order/order_list`, { "order_ids": orderIdsList })
    .then((res) => { if (res.status == 200) return res.data }).catch((err) => { return null });
}

export function fetchDueReasons(): Promise<any[]> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}operation/duedate-changes-reasons/reasons`)
    .then((res) => { return res.data }).catch((err) => { return [] });
}
export function fetchOrderErgency(order_id: string): Promise<any> {
  return axiosInstance
    .get(`${process.env.REACT_APP_API_URL}operation/order-ergency/${order_id}`)
    .then((res) => { return res.data }).catch((err) => { return {} });
}
export function changeDueDate(data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_URL}operation/sla/change-duedate?officehours_id=1`, {
        order_id: data.order_id,
        num_of_shifts: data.num_of_shifts,
        reason: data.reason,
        submitted_by: data.submitted_by,
        status: data.status,
      }).then((res) => { resolve(res.data) }).catch((err) => { reject(err.response.data) });
  })
}

