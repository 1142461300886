import { axiosInstance } from "../store";
import { handlStatusCodeMessage, jsonToFormData } from "../helpers";

export function fetchAllFeatures(page = 0, size: number = 20, status = ''): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/feature?page=${page}&size=${size}&status=${status}`)
        .then((res) => {
            if (res.status == 200) {
                console.log("reeees", res)
                return { features: res.data, totalFeaturesCount: parseInt(res.headers['x-total-count']) }
            }
            else {
                return { features: [], totalFeaturesCount: 0 }

            }
        })
        .catch((err) => {
            return { features: [], totalFeaturesCount: 0 }
        })
}
export function saveFeature(feature: any): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}catalog/feature`, feature)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return res.data.id;
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'catalog' });
                return null;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog', text: err.response?.status == 400 ? err.response?.data?.message : 'Something went wrong' });
            return null;
        })
}
export function getFeatureById(featureId: string): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/feature/${featureId}`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return null

            }
        })
        .catch((err) => {
            return null
        })
}
export function updateFeature(featureId: string, values: any): Promise<any> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}catalog/feature/${featureId}`, values)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return false

            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return false
        })
}
export function addFeatureValue(featureId: string, value: any): Promise<any> {
    let formData = jsonToFormData(value);
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}catalog/feature-variant/${featureId}/variant`, formData)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'catalog' });
                return false;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return false;
        })
}
export function fetchFeatureValues(featureId = 0 || '', sort = "", page = 0, size: number = 10000): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/feature/variants?feature_id=${featureId}&page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            if (res.status == 200) {
                return { featureValues: res.data, totalfeatureValuesCount: parseInt(res.headers['x-total-count']) }
            }
            else {
                return { featureValues: [], totalfeatureValuesCount: 0 }

            }
        })
        .catch((err) => {
            return { featureValues: [], totalfeatureValuesCount: 0 }
        })
}

export function createVariations(featureVariations: any[]): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}catalog/combination/feature-variation`, { feature_variations: featureVariations })
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return []

            }
        })
        .catch((err) => {
            return []
        })
}