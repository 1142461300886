import React, { createContext, useContext, ReactNode, useReducer } from "react";
import { UserGroup, PermissionListResponse,moduleListResponse } from "../dtos";
export enum ActionTypes {
  SET_GROUPS = "SET_GROUPS",
  SET_TOGGLE = "SET_TOGGLE",
  SET_FILTERED_USER_GROUPS = "SET_FILTERED_USER_GROUPS",
  SET_UPDATED_GROUPS = "SET_UPDATED_GROUPS",
  SET_NEW_USER_GROUP_NAME = "SET_NEW_USER_GROUP_NAME",
  SET_USER_GROUP_PERMISSIOSN = "SET_NEW_PERMISSIOSN",
  SET_MODULES = "SET_MODULES",
}
export type State = {
  groups: UserGroup[];
  toggle: boolean[];
  filteredUserGroups: UserGroup[] | null;
  groupsToUpdate: UserGroup[];
  newUserGroupName:string;
  newUserGroupPermissions:PermissionListResponse
  modules:moduleListResponse
};
export type Action =
 
  | { type: ActionTypes.SET_GROUPS; payload: UserGroup[] }
  | { type: ActionTypes.SET_TOGGLE; payload: boolean[] }
  | { type: ActionTypes.SET_FILTERED_USER_GROUPS; payload: UserGroup[] | null }
  | { type: ActionTypes.SET_UPDATED_GROUPS; payload: UserGroup[]}
  | {type: ActionTypes.SET_NEW_USER_GROUP_NAME; payload:string}
  | {type: ActionTypes.SET_USER_GROUP_PERMISSIOSN; payload:PermissionListResponse}
  | {type:ActionTypes.SET_MODULES; payload:moduleListResponse}

interface PermissionsContextProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_GROUPS:
      return { ...state, groups: action.payload };
    case ActionTypes.SET_TOGGLE:
      return { ...state, toggle: action.payload };
    case ActionTypes.SET_FILTERED_USER_GROUPS:
      return { ...state, filteredUserGroups: action.payload };
    case ActionTypes.SET_UPDATED_GROUPS:
      return { ...state, groupsToUpdate: action.payload };
    case ActionTypes.SET_NEW_USER_GROUP_NAME:
      return {...state,newUserGroupName:action.payload};
    case ActionTypes.SET_USER_GROUP_PERMISSIOSN:
      return {...state, newUserGroupPermissions: action.payload}
    case ActionTypes.SET_MODULES:
      return {...state, modules: action.payload};
    default:
      return state;
  }
};
const initialState: State = {
  groups: [],
  toggle: [],
  filteredUserGroups: null,
  groupsToUpdate: [],
  newUserGroupName:"",
  newUserGroupPermissions:[],
  modules:[]
};
const PermissionsContext = createContext<PermissionsContextProps | undefined>(
  undefined
);
export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(
      "usePermissionsContext must be used within a PermissionsProvider"
    );
  }
  return context;
};

export const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PermissionsContext.Provider value={{ state, dispatch }}>
      {children}
    </PermissionsContext.Provider>
  );
};

