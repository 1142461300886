
import { axiosInstance } from "../store";
import { handlStatusCodeMessage, jsonToFormData } from "../helpers";
export function fetchAllBrands(page = 0, sort = 'variantName,asc', size: number = 20): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/feature/variants?feature_id=1&page=${page}&size=${size}&sort=${sort}`)
        .then((res) => {
            if (res.status == 200) {
                return { brands: res.data, totalBrandsCount: parseInt(res.headers['x-total-count']) }
            }
            else {
                return { brands: [], totalBrandsCount: 0 }

            }
        })
        .catch((err) => {
            return { brands: [], totalBrandsCount: 0 }
        })
}

export function fetchBrand(brandId: string): Promise<{ brand: any }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/feature-variant/${brandId}`)
        .then((res) => {
            return res.status == 200 ? res.data : null;
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return null;
        })
}
export function updateBrand(brandId: string, brand: any): Promise<any> {
    let formData = jsonToFormData(brand);
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}catalog/feature-variant/${brandId}`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'catalog' });
                return false;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return false;
        })
}
export function saveBrand(brand: any): Promise<any> {
    let formData = jsonToFormData(brand);
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}catalog/feature-variant/1/variant`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "catalog" });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'catalog' });
                return false;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'catalog' });
            return false;
        })
}
export function fetchAllOriginCountries(page = 0, size: number = 20): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}catalog/feature/variants?feature_id=2&page=${page}&size=${size}`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return []

            }
        })
        .catch((err) => {
            return []
        })
}